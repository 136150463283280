@tailwind base;
@tailwind components;
@tailwind utilities;

.otaPageBg {
  background: linear-gradient(
    168.33deg,
    #f8fdfe -1.02%,
    #f9fbfe 52.81%,
    #fbf9fe 101.51%
  );
}

.otaFont {
  background-image: linear-gradient(
    90deg,
    #07b9ce -14.02%,
    #3969e7 36.11%,
    #7d2ae7 105.67%
  );
  color: transparent;
  background-clip: text;
}

.otaGradientButton {
  background: linear-gradient(
    90deg,
    #07b9ce -14.02%,
    #3969e7 36.11%,
    #7d2ae7 105.67%
  );
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.gradient {
  background: linear-gradient(
    90deg,
    rgba(7, 185, 206, 0.08) 0%,
    rgba(57, 105, 231, 0.08) 52.5%,
    rgba(125, 42, 231, 0.08) 100%
  );
}

.otaGradientOutlineButton {
  border: 3px solid transparent;
  background: linear-gradient(#ffffff, #ffffff),
    linear-gradient(90deg, #07b9ce -14.02%, #3969e7 36.11%, #7d2ae7 105.67%);
  background-origin: border-box;
  background-clip: padding-box, border-box;
  box-shadow: 0px 1px 2px 0px #1018280d;
  padding: 10px 20px;
  display: inline-flex;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
}

.gradient-text {
  background: linear-gradient(
    90deg,
    #07b9ce -14.02%,
    #3969e7 36.11%,
    #7d2ae7 105.67%
  );
  display: inline-block;
  background-clip: text;
  color: transparent;
}

.active-button {
  background: white;
  border: 2px solid;
  border-image-source: linear-gradient(to right, #6a11cb, #2575fc);
  border-image-slice: 1;
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  font-weight: bold;
  border-radius: 9999px;
  padding: 5px 15px;
}

.option-shadow {
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  box-shadow: 0px -2px 0px 0px rgba(16, 24, 40, 0.05) inset;
}

.border-gradient {
  border-image-source: linear-gradient(
    90deg,
    #07b9ce -14.02%,
    #3969e7 36.11%,
    #7d2ae7 105.67%
  );
  border-image-slice: 1;
}

.otaDark {
  color: #121212;
}

.otaLight {
  color: #344054;
}

.cardShadow {
  box-shadow: 0px 1px 2px 0px #1018280d;
}

.article-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
